import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'components/ui/Link';
import { useRouter } from 'next/router';
import Text from 'components/ui/Text';
import styles from './NavItem.module.scss';

const NavItem = ({ children, href, secondary }) => {
    const router = useRouter();

    return (
        <Text as="li" theme="headerNavItem" className={styles.listItem}>
            <Link
                href={href}
                className={cx(styles.root, {
                    [styles.active]: router.asPath === href,
                    [styles.secondary]: secondary,
                })}
            >
                {children}
            </Link>
        </Text>
    );
};

NavItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    href: PropTypes.string.isRequired,
    secondary: PropTypes.bool,
};

export default NavItem;
