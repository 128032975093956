import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Icon.module.scss';

const icons = {
    arrow: {
        viewBox: { width: 23, height: 22 },
        shape: (
            <g fill="none">
                <path
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    d="M11.7,1.2l9.8,9.8l-9.8,9.8"
                />
                <path
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    d="M0.2,11h21.3"
                />
            </g>
        ),
    },
    arrowButton: {
        viewBox: { width: 32, height: 18 },
        shape: (
            <g fill="none">
                <path d="M0 9.104H30" stroke="currentColor" strokeWidth="3" />
                <path
                    d="M22.3335 0.9375L30.5002 9.10417L22.3335 17.2708"
                    stroke="currentColor"
                    strokeWidth="3"
                />
            </g>
        ),
    },
    arrowFooter: {
        viewBox: { width: 32, height: 20 },
        shape: (
            <g fill="none">
                <line
                    x1="-2.18557e-08"
                    y1="9.3584"
                    x2="30"
                    y2="9.3584"
                    stroke="currentColor"
                    strokeWidth="2"
                />
                <path
                    d="M21.8413 0.999999L30.6826 9.84131L21.8413 18.6826"
                    stroke="currentColor"
                    strokeWidth="2"
                />
            </g>
        ),
    },

    arrowCircle: {
        viewBox: { width: 45, height: 45 },
        shape: (
            <g fill="none">
                <path
                    d="M28.7013 19.6878L21.9777 26.4114L15.254 19.6878"
                    stroke="currentColor"
                    strokeWidth="2"
                />
            </g>
        ),
    },

    arrowSmall: {
        viewBox: { width: 22, height: 12 },
        shape: (
            <g fill="none">
                <path d="M0 6H20" stroke="currentColor" strokeWidth="2" />
                <path
                    d="M15 1L20 6L15 11"
                    stroke="currentColor"
                    strokeWidth="2"
                />
            </g>
        ),
    },

    close: {
        viewBox: { width: 18, height: 19 },
        shape: (
            <g>
                <rect
                    width="24.3951"
                    height="1.06066"
                    transform="matrix(0.706528 -0.707685 0.706528 0.707685 6.10352e-05 17.264)"
                    fill="currentColor"
                />
                <rect
                    width="24.3951"
                    height="1.06066"
                    transform="matrix(0.707106 0.707108 -0.707106 0.707108 0.75 0.0143204)"
                    fill="currentColor"
                />
            </g>
        ),
    },

    closeSmall: {
        viewBox: { width: 8, height: 8 },
        shape: (
            <g>
                <path
                    d="M1 1L4 4M4 4L7 1M4 4L7 7M4 4L1 7"
                    stroke="currentColor"
                    strokeWidth="2"
                />
            </g>
        ),
    },

    // social media icons
    facebook: {
        viewBox: { width: 25, height: 25 },
        shape: (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12.819c0 5.966 4.333 10.927 10 11.933v-8.667H7v-3.333h3v-2.667c0-3 1.933-4.666 4.667-4.666.866 0 1.8.133 2.666.266v3.067H15.8c-1.467 0-1.8.733-1.8 1.667v2.333h3.2l-.533 3.333H14v8.667c5.667-1.006 10-5.966 10-11.933C24 6.182 18.6.752 12 .752S0 6.182 0 12.819z"
                fill="currentColor"
            />
        ),
    },
    twitter: {
        viewBox: { width: 23, height: 19 },
        shape: (
            <path
                d="M22.706 2.712c-.835.37-1.732.62-2.675.733A4.67 4.67 0 0022.079.867a9.3 9.3 0 01-2.958 1.13 4.66 4.66 0 00-7.938 4.25 13.229 13.229 0 01-9.602-4.868 4.66 4.66 0 001.442 6.22 4.647 4.647 0 01-2.11-.583v.06a4.66 4.66 0 003.737 4.568 4.692 4.692 0 01-2.104.08 4.661 4.661 0 004.352 3.234 9.348 9.348 0 01-5.786 1.995A9.5 9.5 0 010 16.888a13.175 13.175 0 007.14 2.093c8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602a9.47 9.47 0 002.323-2.41l.002-.003z"
                fill="currentColor"
            />
        ),
    },
    linkedin: {
        viewBox: { width: 23, height: 23 },
        shape: (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.706 2.59A1.838 1.838 0 012.544.752h18.322a1.837 1.837 0 011.84 1.838v18.322a1.84 1.84 0 01-1.839 1.84H2.544a1.837 1.837 0 01-1.838-1.839V2.59zm8.708 6.55h2.98v1.496c.43-.86 1.53-1.634 3.182-1.634 3.17 0 3.92 1.713 3.92 4.856v5.822H16.29v-5.106c0-1.79-.43-2.8-1.522-2.8-1.515 0-2.145 1.089-2.145 2.8v5.106H9.414V9.14zm-5.5 10.403h3.208V9.002H3.914V19.543zM7.581 5.564a2.063 2.063 0 11-4.125.09 2.063 2.063 0 014.125-.09z"
                fill="currentColor"
            />
        ),
    },
    instagram: {
        viewBox: { width: 22, height: 23 },
        shape: (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.465.818C7.638.764 8.012.752 11 .752c2.988 0 3.362.013 4.534.066 1.172.053 1.972.24 2.672.511.733.277 1.398.71 1.948 1.27.56.549.992 1.213 1.268 1.947.272.7.458 1.5.512 2.67.054 1.175.066 1.549.066 4.536 0 2.988-.013 3.362-.066 4.535-.053 1.17-.24 1.97-.512 2.67a5.396 5.396 0 01-1.268 1.949c-.55.56-1.215.992-1.948 1.268-.7.272-1.5.458-2.67.512-1.174.054-1.548.066-4.536.066-2.988 0-3.362-.013-4.535-.066-1.17-.053-1.97-.24-2.67-.512a5.399 5.399 0 01-1.949-1.268 5.393 5.393 0 01-1.269-1.948c-.271-.7-.457-1.5-.511-2.67C.012 15.113 0 14.739 0 11.752 0 8.764.013 8.39.066 7.218c.053-1.172.24-1.972.511-2.672a5.396 5.396 0 011.27-1.948 5.392 5.392 0 011.947-1.269c.7-.271 1.5-.457 2.67-.511h.001zm8.98 1.98c-1.16-.053-1.508-.064-4.445-.064-2.937 0-3.285.01-4.445.064-1.073.049-1.655.228-2.043.379-.513.2-.88.437-1.265.822a3.412 3.412 0 00-.822 1.265c-.151.388-.33.97-.379 2.043-.053 1.16-.064 1.508-.064 4.445 0 2.937.011 3.285.064 4.445.049 1.073.228 1.655.379 2.043.176.477.457.91.822 1.265.355.365.788.646 1.265.822.388.151.97.33 2.043.379 1.16.053 1.507.064 4.445.064 2.938 0 3.285-.011 4.445-.064 1.073-.049 1.655-.228 2.043-.379.513-.2.88-.437 1.265-.822.365-.355.646-.788.822-1.265.151-.388.33-.97.379-2.043.053-1.16.064-1.508.064-4.445 0-2.937-.011-3.285-.064-4.445-.049-1.073-.228-1.655-.379-2.043-.2-.513-.437-.88-.822-1.265a3.413 3.413 0 00-1.265-.822c-.388-.151-.97-.33-2.043-.379zm-5.85 12.345a3.67 3.67 0 102.641-6.846 3.67 3.67 0 00-2.641 6.846zm-2.593-7.39a5.654 5.654 0 117.996 7.997 5.654 5.654 0 01-7.996-7.996zm10.906-.813a1.336 1.336 0 10-1.834-1.945 1.336 1.336 0 001.834 1.945z"
                fill="currentColor"
            />
        ),
    },
    google: {
        viewBox: { width: 1024, height: 1024 },
        shape: (
            <g id="super-g" fill="#000000">
                <path
                    d="M512,197.973333 C608.142222,197.973333 672.995556,239.502222 709.973333,274.204444 L854.471111,133.12 C765.724444,50.6311111 650.24,0 512,0 C311.751111,0 138.808889,114.915556 54.6133333,282.168889 L220.16,410.737778 C261.688889,287.288889 376.604444,197.973333 512,197.973333 L512,197.973333 Z"
                    id="Shape"
                    fill="#EA4335"
                />
                <path
                    d="M1003.52,523.377778 C1003.52,481.28 1000.10667,450.56 992.711111,418.702222 L512,418.702222 L512,608.711111 L794.168889,608.711111 C788.48,655.928889 757.76,727.04 689.493333,774.826667 L851.057778,899.982222 C947.768889,810.666667 1003.52,679.253333 1003.52,523.377778 L1003.52,523.377778 Z"
                    id="Shape"
                    fill="#4285F4"
                />
                <path
                    d="M220.728889,613.262222 C209.92,581.404444 203.662222,547.271111 203.662222,512 C203.662222,476.728889 209.92,442.595556 220.16,410.737778 L54.6133333,282.168889 C19.9111111,351.573333 0,429.511111 0,512 C0,594.488889 19.9111111,672.426667 54.6133333,741.831111 L220.728889,613.262222 L220.728889,613.262222 Z"
                    id="Shape"
                    fill="#FBBC05"
                />
                <path
                    d="M512,1024 C650.24,1024 766.293333,978.488889 851.057778,899.982222 L689.493333,774.826667 C646.257778,804.977778 588.231111,826.026667 512,826.026667 C376.604444,826.026667 261.688889,736.711111 220.728889,613.262222 L55.1822222,741.831111 C139.377778,909.084444 311.751111,1024 512,1024 L512,1024 Z"
                    id="Shape"
                    fill="#34A853"
                />
                <polygon
                    id="Shape"
                    fill="none"
                    points="0 0 1024 0 1024 1024 0 1024"
                />
            </g>
        ),
    },
    chevron8: {
        viewBox: { width: 10, height: 7 },
        shape: (
            <path
                d="M1 1L5 5L9 1"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
            />
        ),
    },
};

const getId = type => `__icon_${type}__`;

export const IconDefs = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
            <defs>
                {Object.entries(icons).map(([type, { shape }]) => (
                    <g key={type} id={getId(type)}>
                        {shape}
                    </g>
                ))}
            </defs>
        </svg>
    );
};

const Icon = ({ type, className, style, ...otherProps }) => {
    const iconDef = icons[type] || {
        viewBox: { width: 24, height: 24 },
        shape: <circle cx="12" cy="12" r="12" fill="currentColor" />,
    };
    const viewBox = `0 0 ${iconDef.viewBox.width} ${iconDef.viewBox.height}`;
    return (
        <span
            className={cx(styles.root, className)}
            style={style}
            {...otherProps}
        >
            <svg
                viewBox={viewBox}
                className={styles.svg}
                xmlns="http://www.w3.org/2000/svg"
            >
                <use xlinkHref={`#${getId(type)}`}></use>
            </svg>
        </span>
    );
};

export const getViewBoxSize = type => {
    const iconDef = icons[type] || {
        viewBox: { width: 24, height: 24 },
    };
    return iconDef.viewBox;
};

Icon.propTypes = {
    type: PropTypes.oneOf(Object.keys(icons)),
    className: PropTypes.string,
    style: PropTypes.object,
};

export default Icon;
