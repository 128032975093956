import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'components/ui/Link';
import styles from './Logo.module.scss';

const Logo = ({ href = '/', isMenuOpen = false }) => {
    return (
        <div className={cx(styles.root, isMenuOpen && styles.isMenuOpen)}>
            <Link className={styles.link} href={href}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 136 22"
                    fill="none"
                >
                    <path
                        fill="currentColor"
                        d="M0 0h6.845l4.141 13.922h.056L15.128 0H22v21.153h-4.901l.169-15.836-.057-.058-4.45 15.894H9.24L4.789 5.26l-.057.057.17 15.837H0V0ZM40.065 16.511c-.782 1.773-1.912 3.145-3.274 4.06-1.333.943-2.927 1.429-4.58 1.429-4.607 0-8.519-3.688-8.519-8.404 0-4.43 3.535-8.52 8.433-8.52 4.897 0 8.49 3.832 8.49 8.663 0 .629-.058.886-.115 1.257H28.445c.29 1.887 1.883 3.001 3.767 3.001 1.477 0 2.26-.657 2.926-1.486h4.927Zm-4.26-4.716c-.203-.944-1.16-2.716-3.651-2.716-2.493 0-3.449 1.772-3.652 2.716h7.303ZM41.462 0h4.871v11.034h.059l4.52-5.745h5.78l-6.133 6.975 5.958 8.89H51l-4.607-7.633h-.06v7.633h-4.871V0Z"
                    />
                    <path
                        fill="currentColor"
                        d="M72.77 21.457h-4.844v-1.712h-.059C67.05 21.286 65.037 22 63.14 22 58.005 22 55 18.061 55 13.524c0-5.194 3.822-8.447 8.14-8.447 2.655 0 4.143 1.255 4.727 2.226h.058V5.619h4.844v15.838Zm-8.9-3.88c3.034 0 4.056-2.512 4.056-4.053 0-1.911-1.343-4.024-4.085-4.024-2.772 0-3.997 2.284-3.997 4.08 0 1.485 1.108 3.997 4.026 3.997ZM74.462 5.61h4.592v1.652h.06c.504-.757 1.54-2.185 4.474-2.185 1.571 0 3.319.616 4.208 1.344.89.728 1.896 1.961 1.896 5.014v9.719h-4.919v-8.487c0-1.092 0-3.22-2.578-3.22-2.815 0-2.815 2.296-2.815 3.249v8.458h-4.918V5.609ZM96.462 3.602h-5.077V0h5.076v3.602Zm0 17.552h-5.077V5.288h5.076v15.866ZM104.945 10.07c-.028-.342-.084-1.199-1.46-1.199-.983 0-1.32.628-1.32 1.057 0 .856 1.151 1.34 2.387 1.712 2.64.742 5.448 1.541 5.448 4.966 0 3.339-2.892 5.394-6.346 5.394-2.359 0-5.925-1.084-6.346-5.251h4.633c.196 1.455 1.572 1.455 1.769 1.455.786 0 1.628-.456 1.628-1.227 0-1.227-1.151-1.37-4.127-2.54-2.022-.656-3.707-1.998-3.707-4.138 0-3.14 2.809-5.222 6.038-5.222 2.05 0 5.588.828 5.98 4.994h-4.577ZM110.846 5.61h4.464v1.652h.058c.374-.644 1.411-2.185 4.406-2.185 1.412 0 3.341.476 4.608 2.773.979-1.513 2.621-2.773 5.069-2.773 1.123 0 2.909.252 4.205 1.568 1.498 1.54 1.729 2.577 1.729 6.022v8.486h-4.781v-8.262c0-1.26 0-3.445-2.449-3.445-2.649 0-2.649 2.353-2.649 3.22v8.487h-4.781v-8.458c0-.84 0-3.249-2.391-3.249-2.707 0-2.707 2.521-2.707 3.445v8.263h-4.781V5.609Z"
                    />
                </svg>
            </Link>
        </div>
    );
};

Logo.propTypes = {
    href: PropTypes.string,
    isMenuOpen: PropTypes.bool,
};

export default Logo;
