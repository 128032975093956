import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

const FooterItem = ({ children, href, slug }) => (
    <Text as="li" theme="footerNavItem">
        {slug ? (
            <Link href={slug}>{children}</Link>
        ) : (
            <a href={href} target="_blank" rel="noreferrer">
                {children}
            </a>
        )}
    </Text>
);

FooterItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    href: PropTypes.string,
    slug: PropTypes.string,
};

export default FooterItem;
