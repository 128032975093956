export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export const isBrowser = typeof document !== 'undefined';

export const scaleVal = (value, from, to) => {
    let scale = (to[1] - to[0]) / (from[1] - from[0]);
    let capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
    return capped * scale + to[0];
}; //https://gist.github.com/fpillet/993002

export function wait(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

// Tip: use with await.
// const preloaded = await preloadAll(['img1.jpg', 'img2.jpg'])

export const preload = src =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
    });

export const preloadAll = srcs => Promise.all(srcs.map(preload));

// Site breakpoints
export const breakpoints = {
    small: 640,
    medium: 1120,
    large: 1440,
};

export const mekSocials = [
    {
        name: 'instagram',
        href: 'https://www.instagram.com/mekanism/',
    },
    {
        name: 'facebook',
        href: 'https://www.facebook.com/mekanism',
    },
    {
        name: 'twitter',
        href: 'https://twitter.com/mekanism',
    },
    {
        name: 'linkedin',
        href: 'https://www.linkedin.com/company/mekanism/',
    },
];

export const colors = {
    black: '#010101',
    white: '#ffffff',
    grayLight: '#f2f1ed',
    gray: '#d1d0cc',
    grayDark: '#9b9a97',
    grayDarker: '#767573',
    beige: '#faf9f4',
    beigeDark: '#f2f1ed',
    pink: '#fa1682',
    blue: '#74ccdf',
    gold: '#d7ae5c',
    purple: '#a47eb9',
    green: '#74e1c1',
};

export const formStates = {
    INVALID: 'invalid',
    VALID: 'valid',
    SUBMITTING: 'submitting',
    SUBMITTED: 'submitted',
    ERROR: 'error',
};

export function emailIsValid(email = '') {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const downloadFile = (fileURL = '', fileName = true, headers = {}) => {
    fetch(fileURL, {
        method: 'GET',
        headers: headers,
    })
        .then(response => response.blob())
        .then(blob => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
};

export function getReadableMonth(idx) {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    return months[idx];
}

export function getFormattedDate(date) {
    return new Date(date);
}

export function getWeekDay() {
    const date = new Date();
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    return days[date.getUTCDay()];
}

export const lerp = (a, b, n) => {
    return (1 - n) * a + n * b;
};

export function isEmpty(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
