import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Burger.module.scss';

const Burger = ({ isMenuOpen, onClick }) => {
    return (
        <div
            className={cx(styles.root, isMenuOpen && styles.isMenuOpen)}
            onClick={onClick}
        >
            <span></span>
            <span></span>
        </div>
    );
};

Burger.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Burger;
