import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/ui/Text';
import styles from './Checkbox.module.scss';

const Checkbox = ({ name, id, checked, onChange, required, label }) => {
    return (
        <div className={styles.root}>
            <input
                type="checkbox"
                name={name}
                id={id}
                className={styles.input}
                checked={checked}
                required={required}
                onChange={onChange}
            />
            <Text
                as="label"
                theme="label"
                className={styles.label}
                htmlFor={id || name}
            >
                {label}
            </Text>
        </div>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool,
};

Checkbox.defaultProps = {
    checked: false,
    id: '',
    label: '',
    onChange: () => {},
    required: false,
};

export default Checkbox;
