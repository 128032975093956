import React from 'react';
import Icon from 'components/ui/Icon';
import Text from 'components/ui/Text';
import NewsletterForm from 'components/ui/NewsletterForm';
import Link from 'components/ui/Link';
import { mekSocials } from 'utils';
import styles from './Footer.module.scss';
import FooterItem from './FooterItem';

const Footer = ({
    newsletterTitle,
    newsletterCallout,
    locationTitle,
    locationItems,
    contactColumnTitle,
    contactColumnItems,
    legalItems,
}) => {
    return (
        <div className={styles.footerContainer}>
            <footer className={styles.root}>
                <div className={styles.tagline}>
                    <div>
                        <Text as="h2" theme="footerCallout">
                            Don&apos;t be shy.
                        </Text>
                        <Text as="h2" theme="footerCallout">
                            come say hi.
                        </Text>
                    </div>
                </div>

                {/* main navigation */}
                <div className={styles.contactContainer}>
                    <Text theme="label" className={styles.subHeading}>
                        {contactColumnTitle}
                    </Text>
                    {contactColumnItems.map((item, i) => (
                        <div key={i}>
                            <Text
                                className={styles.contactSubject}
                                theme="footerNavItem"
                            >
                                {item.title}
                            </Text>
                            <a href={`mailto:${item.email}`}>
                                <Text theme="footerNavItem">{item.email}</Text>
                            </a>
                        </div>
                    ))}
                </div>

                <div className={styles.locationsContainer}>
                    <Text theme="label" className={styles.subHeading}>
                        {locationTitle}
                    </Text>

                    <ul>
                        {locationItems.map((item, i) => (
                            <FooterItem key={i} href={item.externalLink}>
                                {item.label}
                            </FooterItem>
                        ))}
                    </ul>
                </div>

                <div className={styles.newsLetterContainer}>
                    <Text theme="label" className={styles.subHeading}>
                        {newsletterTitle}
                    </Text>
                    <div className={styles.newsLetterForm}>
                        <Text
                            theme="label"
                            className={styles.subHeadingNewsletter}
                        >
                            {newsletterCallout}
                        </Text>
                        <NewsletterForm />
                    </div>
                </div>

                {/* secondary */}
                <div className={styles.followContainer}>
                    <Text theme="label" className={styles.subHeading}>
                        Follow Us
                    </Text>

                    <ul className={styles.social}>
                        {mekSocials.map(({ name, href }, i) => (
                            <li key={i}>
                                <a
                                    href={href}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    aria-label={name}
                                >
                                    <Icon type={name} />
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles.copyrightNotice}>
                    <Text theme="footerNavItem">
                        © {new Date().getFullYear()} Mekanism{' '}
                        {legalItems.map((item, i) => (
                            <span key={i}>
                                <strong>•</strong>{' '}
                                <Link href={`/${item.slug}`}>
                                    {item.label}{' '}
                                </Link>
                            </span>
                        ))}
                    </Text>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
