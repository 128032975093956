import 'styles/global/reset.css';
import 'styles/global/theme.scss';
import 'styles/global/zindex.scss';
import 'styles/global/spacing.scss';
import 'styles/global/grid.scss';
import 'styles/global/one-trust.scss';

import '@madeinhaus/nextjs-page-transition/dist/index.css';
import '@madeinhaus/carousel/dist/index.css';

import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Script from 'next/script';
import PageTransition, {
    useAsPathWithoutHash,
} from '@madeinhaus/nextjs-page-transition';
import Header from 'components/global/Header';
import Footer from 'components/global/Footer';
import PageLoader from 'components/global/PageLoader';
import { IconDefs } from 'components/ui/Icon';
import { GTM_ID } from 'utils';
import { GlobalProvider } from 'utils/context/globalContext';

const MekanismApp = ({
    Component,
    pageProps: { header, footer, ...pageProps },
}) => {
    const key = useAsPathWithoutHash();

    return (
        <React.Fragment>
            <Head>
                <title>Mekanism</title>
                <meta
                    name="description"
                    content="Mekanism Advertising Agency is a top creative agency with advertising agencies in San Francisco, New York (NYC), Chicago & Seattle."
                />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, viewport-fit=cover"
                />
                <meta
                    key="og:url"
                    property="og:url"
                    content="https://mekanism.com"
                />
                <meta key="og:type" property="og:type" content="website" />
                <meta key="og:title" property="og:title" content="Mekanism" />
                <meta
                    key="og:description"
                    property="og:description"
                    content="Mekanism Advertising Agency is a top creative agency with advertising agencies in San Francisco, New York (NYC), Chicago & Seattle."
                />
                <meta
                    key="og:image"
                    property="og:image"
                    content="https://mekanism.com/share.jpg"
                />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@mekanism" />
                <meta name="twitter:creator" content="@mekanism" />
                <meta name="twitter:title" content="Mekanism" />
                <meta
                    name="twitter:description"
                    content="Mekanism Advertising Agency is a top creative agency with advertising agencies in San Francisco, New York (NYC), Chicago & Seattle."
                />
                <meta
                    name="twitter:image"
                    content="https://mekanism.com/share.jpg"
                />
            </Head>
            <IconDefs />
            <GlobalProvider>
                <Header
                    credentialProviders={pageProps.credentialProviders}
                    {...header}
                />
                <PageLoader />
                <PageTransition>
                    <Component key={key} {...pageProps} />
                </PageTransition>
                <Footer {...footer} />
            </GlobalProvider>
            {process.env.ENV === 'production' && (
                <Script
                    id="gtag-base"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
                    }}
                />
            )}
        </React.Fragment>
    );
};

MekanismApp.propTypes = {
    Component: PropTypes.any.isRequired,
    pageProps: PropTypes.shape({
        contentTypeId: PropTypes.string,
        session: PropTypes.object,
        credentialProviders: PropTypes.object,
    }),
};

export default MekanismApp;
