import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useMedia, useWindowScroll } from 'react-use';
import Nav from 'components/global/Nav';
import Logo from 'components/ui/Logo';
import Burger from 'components/ui/Burger';
import useScrollDirection, { SCROLL_DOWN } from 'hooks/useScrollDirection';
import {
    useGlobalStateContext,
    useGlobalDispatchContext,
    SET_PREVENT_PEEKING,
} from 'utils/context/globalContext';
import styles from './Header.module.scss';

const Header = ({ credentialProviders, navItems }) => {
    const router = useRouter();
    const isMedium = useMedia('(min-width: 1120px)', false);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const { preventPeeking } = useGlobalStateContext();
    const dispatch = useGlobalDispatchContext();

    const scrollDirection = useScrollDirection();
    const { y: pageScrollY } = useWindowScroll();

    const hideMenu = useCallback(() => {
        setMenuOpen(false);
        dispatch({
            type: SET_PREVENT_PEEKING,
            preventPeeking: false,
        });
    }, [dispatch]);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleKeyup = useCallback(
        e => {
            if (e.keyCode == 27) {
                hideMenu();
            }
        },
        [hideMenu]
    );

    useEffect(() => {
        router.events.on('routeChangeComplete', hideMenu);
        return () => {
            router.events.off();
        };
    }, [hideMenu, router.events]);

    useEffect(() => {
        document.addEventListener('keyup', handleKeyup);
        return () => {
            document.removeEventListener('keyup', handleKeyup);
        };
    }, [handleKeyup]);

    return (
        <header
            className={cx(styles.root, {
                [styles.isHidden]:
                    scrollDirection === SCROLL_DOWN || preventPeeking === true,
                [styles.hasFill]: pageScrollY > 100 && !isMenuOpen,
            })}
        >
            <div className={styles.gridContainer}>
                <div className={styles.logoContainer}>
                    <Logo isMenuOpen={isMenuOpen && !isMedium} />
                </div>
                <div className={styles.menuContainer}>
                    <Burger
                        isMenuOpen={isMenuOpen && !isMedium}
                        onClick={toggleMenu}
                    />
                </div>
                <div className={styles.navContainer}>
                    <Nav
                        items={navItems}
                        isMenuOpen={isMenuOpen && !isMedium}
                        credentialProviders={credentialProviders}
                    />
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    credentialProviders: PropTypes.object,
};

export default Header;
