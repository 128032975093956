import { useEffect } from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import styles from './PageLoader.module.scss';

const PageLoader = () => {
    useEffect(() => {
        NProgress.configure({
            speed: 500,
            minimum: 0.2,
            trickle: true,
            trickleSpeed: 100,
            showSpinner: false,
            template: `<div class="${styles.loader}" role="bar"></div>`,
        });
        Router.onRouteChangeStart = () => {
            NProgress.start();
        };
        Router.onRouteChangeComplete = () => {
            NProgress.done();
        };
        Router.onRouteChangeError = () => {
            NProgress.done();
        };
        return () => {
            Router.onRouteChangeStart = null;
            Router.onRouteChangeComplete = null;
            Router.onRouteChangeError = null;
        };
    }, []);
    return null;
};

export default PageLoader;
