import React from 'react';
import PropTypes from 'prop-types';
import { default as NextLink } from 'next/link';

const Link = ({ children, href, scroll, ...props }) => {
    return (
        <NextLink href={href} scroll={scroll} {...props}>
            {children}
        </NextLink>
    );
};

Link.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    href: PropTypes.string.isRequired,
    scroll: PropTypes.bool,
};

Link.defaultProps = {
    scroll: false,
};

export default Link;
