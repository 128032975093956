import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

export const SET_PREVENT_PEEKING = 'SET_PREVENT_PEEKING';
export const SET_IS_VIDEO_PLAYING = 'SET_IS_VIDEO_PLAYING';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_PREVENT_PEEKING:
            return {
                ...state,
                preventPeeking: action.preventPeeking,
            };
        case SET_IS_VIDEO_PLAYING:
            return {
                ...state,
                isVideoPlaying: action.isVideoPlaying,
            };
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
};

const defaultState = {
    preventPeeking: false,
    isVideoPlaying: false,
};

export const GlobalProvider = ({ children, data }) => {
    const [state, dispatch] = useReducer(reducer, {
        ...defaultState,
        ...data,
    });

    return (
        <GlobalDispatchContext.Provider value={dispatch}>
            <GlobalStateContext.Provider value={state}>
                {children}
            </GlobalStateContext.Provider>
        </GlobalDispatchContext.Provider>
    );
};

GlobalProvider.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
};

export const useGlobalStateContext = () => useContext(GlobalStateContext);
export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext);
