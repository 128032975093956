import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Icon from 'components/ui/Icon';
import { emailIsValid, downloadFile, formStates } from 'utils';
import Text from 'components/ui/Text';
import Checkbox from '../Checkbox';
import styles from './NewsletterForm.module.scss';

const NewsletterForm = () => {
    const formRef = useRef(null);
    const { INVALID, VALID, SUBMITTING, SUBMITTED } = formStates;
    const [emailValue, setEmailValue] = useState('');
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [companyValue, setCompanyValue] = useState('');
    const [oneSheetValue, setOneSheetValue] = useState(false);
    const [formState, setFormState] = useState(INVALID);
    // eslint-disable-next-line no-unused-vars
    const [showOneSheet, setShowOneSheet] = useState(false);

    const subscribe = async e => {
        e.preventDefault();

        setFormState(SUBMITTING);

        const res = await fetch('/api/subscribe', {
            body: JSON.stringify({
                email: emailValue,
                firstName: firstNameValue,
                lastName: lastNameValue,
                company: companyValue,
                oneSheet: oneSheetValue,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
        });

        const { error } = await res.json();

        if (oneSheetValue) {
            downloadFile('/pdfs/mek-one-pager.pdf', 'mek-one-pager.pdf', {
                'Content-Type': 'application/pdf',
            });
        }

        if (error) {
            // Intentionally pushing errors through
            // to make it harder for people to discover
            // who's on the email list
            setFormState(SUBMITTED);
            return;
        }

        setFormState(SUBMITTED);
        return;
    };

    useEffect(() => {
        setFormState(emailIsValid(emailValue) ? VALID : INVALID);
    }, [INVALID, VALID, emailValue]);

    useEffect(() => {
        formState === SUBMITTED && formRef.current.reset();
    }, [SUBMITTED, formState]);

    const emailPlaceholder = () => {
        switch (formState) {
            case SUBMITTED:
                return "We'll be in touch!";
            default:
                return 'Your Email';
        }
    };

    return (
        <form
            ref={formRef}
            className={cx(styles.root, styles[formState])}
            onSubmit={subscribe}
        >
            <section className={cx(styles.section, styles.primaryFields)}>
                <input
                    autoComplete="email-mek" // intentionally block autocomplete
                    type="email"
                    name="email"
                    className={styles.input}
                    placeholder={emailPlaceholder()}
                    maxLength="64"
                    required
                    onChange={e => setEmailValue(e.currentTarget.value)}
                />
            </section>
            <section className={cx(styles.section, styles.secondaryFields)}>
                <input
                    type="text"
                    name="firstName"
                    className={cx(styles.input, styles.firstName)}
                    placeholder="First Name"
                    maxLength="64"
                    required={false}
                    onChange={e => setFirstNameValue(e.currentTarget.value)}
                />
                <input
                    type="text"
                    name="lastName"
                    className={styles.input}
                    placeholder="Last Name"
                    maxLength="64"
                    required={false}
                    onChange={e => setLastNameValue(e.currentTarget.value)}
                />
            </section>
            <section className={cx(styles.section, styles.secondaryFields)}>
                <input
                    type="text"
                    name="company"
                    className={styles.input}
                    placeholder="Company"
                    maxLength="64"
                    required={false}
                    onChange={e => setCompanyValue(e.currentTarget.value)}
                />
            </section>
            {showOneSheet && (
                <section className={cx(styles.section, styles.primaryFields)}>
                    <Checkbox
                        label="Mek TL;DR (download our one-pager)"
                        name="oneSheet"
                        id="oneSheet"
                        checked={oneSheetValue}
                        onChange={() => setOneSheetValue(!oneSheetValue)}
                    />

                    <button
                        type="submit"
                        aria-label="Submit"
                        className={styles.submitButton}
                        disabled={formState !== VALID}
                    >
                        <Icon
                            type="arrowFooter"
                            className={styles.submitIcon}
                        />
                    </button>
                </section>
            )}
            {!showOneSheet && (
                <section
                    className={cx(
                        styles.section,
                        styles.primaryFields,
                        styles.submit
                    )}
                >
                    <button
                        type="submit"
                        aria-label="Submit"
                        className={styles.submitButton}
                        disabled={formState !== VALID}
                    >
                        <Text theme="label" as="span">
                            Submit
                        </Text>
                        <Icon
                            type="arrowFooter"
                            className={styles.submitIcon}
                        />
                    </button>
                </section>
            )}
        </form>
    );
};

export default NewsletterForm;
