import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { mekSocials } from 'utils';
import Icon from 'components/ui/Icon';
import NavItem from './NavItem';
import styles from './Nav.module.scss';

const Nav = ({ items, isMenuOpen }) => {
    const menu = useRef(null);

    useEffect(() => {
        isMenuOpen
            ? disableBodyScroll(menu.current)
            : enableBodyScroll(menu.current);
        return () => {
            clearAllBodyScrollLocks();
        };
    }, [isMenuOpen]);

    return (
        <nav
            ref={menu}
            className={cx(styles.root, {
                [styles.isMenuOpen]: isMenuOpen,
            })}
        >
            <ul className={styles.links}>
                {items.map((item, i) => {
                    {
                        /* Different contact layout in ground control */
                    }
                    if (item.slug === 'contact') {
                        return (
                            <NavItem key={i} href={`/${item.slug}`}>
                                {item.label}
                            </NavItem>
                        );
                    }

                    return (
                        <NavItem key={i} href={`/${item.slug}`}>
                            {item.label}
                        </NavItem>
                    );
                })}
            </ul>
            <ul className={styles.social}>
                {mekSocials.map(({ name, href }) => (
                    <li key={name}>
                        <a
                            href={href}
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={name}
                        >
                            <Icon type={name} />
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

Nav.propTypes = {
    isMenuOpen: PropTypes.bool,
    credentialProviders: PropTypes.object,
};

Nav.defaultProps = {
    isMenuOpen: false,
};

export default Nav;
